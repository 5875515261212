import { render, staticRenderFns } from "./TableItemConfiguration.vue?vue&type=template&id=55b7cde0&scoped=true"
import script from "./TableItemConfiguration.vue?vue&type=script&lang=js"
export * from "./TableItemConfiguration.vue?vue&type=script&lang=js"
import style0 from "./TableItemConfiguration.vue?vue&type=style&index=0&id=55b7cde0&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55b7cde0",
  null
  
)

export default component.exports